import "./home.css";
import igIcon from "../../assets/img/ig.png";
import fbIcon from "../../assets/img/fb.png";
import ytIcon from "../../assets/img/yt.png";
import arrowIcon from "../../assets/img/arrow.png";
import logo from "../../assets/img/logo.png";

export default function Home() {
  return (
    <>
      <div className="container">
        <div className="logo">
          <img src={logo} alt="" />
          <div className="desc">
            <p>Where nature meets you and you meet experience.</p>
            <p
              className="menu-btn"
              onClick={() => {
                window.open("/menu");
              }}
            >
              Menu
            </p>
          </div>
        </div>

        <div className="location">
          <div className="icons">
            <ul>
              <li>
                <a
                  href="https://instagram.com/_.ukusa._?igshid=NmE0MzVhZDY="
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={igIcon} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/ukusa.in"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fbIcon} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCMq4KCFlkRpU3rF65OJXg6Q"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={ytIcon} alt="" />
                </a>
              </li>
            </ul>
          </div>
          <p>
            <a
              href="https://goo.gl/maps/r5zp3Tv6pX7JridJ6"
              target="_blank"
              rel="noreferrer"
            >
              You can find us here <img src={arrowIcon} alt="" />
            </a>
          </p>
        </div>
        <p className="powered-line">
          Powered By{" "}
          <a
            href="https://vaikharidigital.com"
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            Vaikhari Digital
          </a>
        </p>
      </div>
    </>
  );
}
