import './App.css';
import Home from './pages/home/Home'
import Menu from './pages/menu/Menu';
import { Route, Routes } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/menu" element={<Menu/>} />
          <Route path="*" to="/" element={<Home/>} />
      </Routes>
    </div>
  );
}

export default App;
