import "./menu.css";
import image1 from "../../assets/img/menu/page-1.jpg";
import image2 from "../../assets/img/menu/page-2.jpg";
import image3 from "../../assets/img/menu/page-3.jpg";
import image4 from "../../assets/img/menu/page-4.jpg";
import image5 from "../../assets/img/menu/page-5.jpg";
import image6 from "../../assets/img/menu/page-6.jpg";
import image7 from "../../assets/img/menu/page-7.jpg";
import image8 from "../../assets/img/menu/page-8.jpg";
import image9 from "../../assets/img/menu/page-9.jpg";
import image10 from "../../assets/img/menu/page-10.jpg";
import image11 from "../../assets/img/menu/page-11.jpg";
import image12 from "../../assets/img/menu/page-12.jpg";
export default function Menu() {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
  ];
  return (
    <>
      <div className="menu-images">
        {images.map((path, index) => {
          return (
            <img
              src={path}
              alt={`menu${index}`}
              className="menu-image"
              key={`${path}+${index}`}
            />
          );
        })}
      </div>
    </>
  );
}
